@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"



















































































































































































.sas-pagination
  margin: $size-s 0
  +flex-space-between

  &__count
    color: $color-ink-light

  &__pages
    +flex-center

  .sas-page-button
    +space-inset(0 $size-s)
    +space-inline($size-xxs)

    &.--active
      background: rgba($color-ink, 0.25)
      color: $color-ink
